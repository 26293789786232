/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Visibility from '@atlaskit/icon/glyph/watch-filled';
import Spinner from '@atlaskit/spinner';
import { Checkbox } from '@atlaskit/checkbox';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/styles';
import newRelicUtils from '@root/utils/newRelicUtils';
import { PAGE_ACTION } from '@root/utils/pageAction';
import { SignupCheckStatus } from '@root/utils';
import { authAPIs } from '@root/services';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/d_accel_yoko.png';
import errorMessageAuth from './common';

const useStyles = makeStyles(() => ({
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '332px',
    height: '100%',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: '192px',
  },
  signUpTitle: {
    fontWeight: '600',
    fontSize: '24px',
    color: '#091E42',
    marginTop: '17px',
  },
  wordEmailField: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '60px',
    width: '100%',
  },

  messageCheckPass: {
    borderRadius: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    background: 'green',
    left: '0',
    color: '#fff',
    textAlign: 'left',
    alignItems: 'center',
    fontWeight: 'normal',
    fontSize: '16px',
    display: 'flex',
    padding: '5px 15px',
    justifyContent: 'space-between',
  },

  wordEmailTitle: {
    fontWeight: '700',
    fontSize: '16px',
    color: '#6D6E6F',
  },
  input: {
    '& > input': {
      marginTop: '13px',
      color: '#000',
      border: '1px solid #C1C7D0',
      height: '40px',
      fontSize: '14px',
      background: ' #F5F5F5',
      boxSizing: 'border-box',
      fontWeight: 500,
      borderRadius: '20px',
      padding: '0 10px 1px 18px',
      '&:focus': {
        border: '1px solid #FAD100',
        background: '#F5F5F5 !important',
      },
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
    '& > select': {
      '&:-webkit-autofill': {
        '&:focus': {
          WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
          boxShadow: '0 0 0 2px #fad10042 !important',
        },
        WebkitBoxShadow: '0 0 0px 1000px #F5F5F5 inset !important',
      },
    },
  },

  passwordField: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px',
    width: '100%',
  },
  privacyField: {
    display: 'flex',
    width: '100%',
    marginTop: '28px',
    '& a': {
      color: '#359be7',
      textDecoration: 'none',
      '&:hover': {
        opacity: '0.8',
        textDecoration: 'underline',
      },
    },
  },
  passwordTitle: {
    fontWeight: '700',
    fontSize: '16px',
    color: '#6D6E6F',
  },
  eyesIcon: {
    position: 'absolute',
    right: '11px',
    top: '62%',
    '& span[data-testid="visibilityOff"]': {
      position: 'relative',
      '&:before': {
        content: '""',
        width: '26px',
        height: '2px',
        position: 'absolute',
        background: 'currentColor',
        transform: 'rotate(45deg)',
        top: '3px',
        left: '3px',
        transformOrigin: 'top left',
      },
      '&:after': {
        content: '""',
        width: '26px',
        height: '2px',
        position: 'absolute',
        background: '#fff',
        transform: 'rotate(45deg)',
        top: '1px',
        left: '4px',
        transformOrigin: 'top left',
      },
    },
  },
  signUpNow: {
    display: 'flex',
    width: '100%',
    fontWeight: '500',
    fontSize: '14px',
    color: '#6D6E6F',
    marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
      textDecoration: 'underline',
    },
  },
  signUpButton: {
    cursor: 'pointer',
    background: 'linear-gradient(92.7deg, #EC6423 -20.42%, #FAD100 114.43%)',
    borderRadius: '20px',
    border: 'none',
    fontWeight: 700,
    fontSize: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '47px',
    alignItems: 'center',
    marginTop: '48px',
    transition: 'opacity 0.5s',
    '&:hover': {
      opacity: '0.85',
      color: '#FFFFFF',
    },
    color: '#F0FFF0',
  },
  buttonDisabled: {
    cursor: 'not-allowed',
    color: '#A5ADBA',
    textDecoration: 'none',
    background: '#091E420A',
    '&:hover': {
      opacity: '1',
      color: '#A5ADBA',
    },
  },
  errorMessageContainer: {
    color: '#D92929',
    fontSize: '16px',
    width: '100%',
    marginTop: '10px',
    display: 'flex',
  },
  errorMessage: {
    marginLeft: '5px',
  },
  callToActionContainer: {
    display: 'flex',
    fontSize: '14px',
    color: '#6D6E6F',
    margin: '10px 0',
  },
  linkAction: {
    fontWeight: '500',
    color: '#359be7',
    marginLeft: '5px',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
      textDecoration: 'underline',
    },
  },
}));

const Signup = ({ setSignup }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCheckAgreePrivacy, setIsCheckAgreePrivacy] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    email: null,
    password: null,
    confirmPassword: null,
  });
  const navigate = useNavigate();

  const emailInputRef = useRef(null);

  const handleClickShowPassWord = (event) => {
    event.preventDefault();
    setIsShowPassword(!isShowPassword);
  };

  const handleClickShowConfirmPassWord = (event) => {
    event.preventDefault();
    setIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const handleSignup = async () => {
    setIsLoading(true);
    newRelicUtils.addPageAction(PAGE_ACTION.signup.signup, {
      userEmail: email,
      userId: null,
    });
    try {
      const response = await authAPIs.signup({
        email,
        password,
        repassword: confirmPassword,
      });
      if (response?.status === 200 && response?.data?.success) {
        const { data } = response.data;
        const { user_id: userId, user_confirmed: userConfirmed } = data;

        if (!userConfirmed) {
          setSignup(SignupCheckStatus.NOT_CHECK_YET);
          setIsLoading(false);
          navigate(`/verify-email/${userId}`, { state: { email } });
        }
      } else {
        const { errors } = response.data;
        errors.forEach((err) => {
          if (err.param === 'email') {
            setErrorMessage((prev) => ({
              ...prev,
              email: errorMessageAuth(err.msg),
            }));
          }
          if (err.param === 'password') {
            setErrorMessage((prev) => ({
              ...prev,
              password: errorMessageAuth(err.msg),
            }));
          }
          if (err.param === 'repassword') {
            setErrorMessage((prev) => ({
              ...prev,
              confirmPassword: errorMessageAuth(err.msg),
            }));
          }
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Signup failed:', error);
      setIsLoading(false);
    }
  };

  const handleLoginNow = (event) => {
    event.preventDefault();
    navigate('/login', { replace: true });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    await handleSignup();
  };

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  return (
    <>
      <form
        onSubmit={onSubmit}
        className={classes.signUpForm}
        autoComplete='off'
      >
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt='Daccel' src={logo} />
        </div>
        <span className={classes.signUpTitle}>
          {t('auth.signup.signupTitle')}
        </span>
        <>
          <div className={classes.wordEmailField}>
            <span className={classes.wordEmailTitle}>
              {t('auth.login.workEmailFormTitle')}
            </span>
            <Input
              inputRef={emailInputRef}
              onFocus={() =>
                setErrorMessage((prev) => ({ ...prev, email: null }))
              }
              required
              disabled={isLoading}
              autoComplete='new-email'
              className={classes.input}
              disableUnderline
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder={t('auth.login.workEmailPlaceholder')}
              onBlur={() => {
                setEmail(email.trim());
              }}
            />
            {errorMessage.email && (
              <div className={classes.errorMessageContainer}>
                <WarningIcon />
                <span className={classes.errorMessage}>
                  {errorMessage.email}
                </span>
              </div>
            )}
          </div>
          <div className={classes.passwordField}>
            <span className={classes.passwordTitle}>
              {t('auth.login.passwordTitle')}
            </span>
            <Input
              onFocus={() =>
                setErrorMessage((prev) => ({ ...prev, password: null }))
              }
              required
              disabled={isLoading}
              autoComplete='new-password'
              type={isShowPassword ? 'text' : 'password'}
              className={classes.input}
              disableUnderline
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onBlur={() => {
                setPassword(password.trim());
              }}
              placeholder={t('auth.login.passwordPlaceholder')}
              endAdornment={
                <InputAdornment position='end' className={classes.eyesIcon}>
                  <IconButton onClick={handleClickShowPassWord} size='small'>
                    {isShowPassword ? (
                      <Visibility />
                    ) : (
                      <Visibility testId='visibilityOff' />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errorMessage.password && (
              <div className={classes.errorMessageContainer}>
                <WarningIcon />
                <span className={classes.errorMessage}>
                  {errorMessage.password}
                </span>
              </div>
            )}
          </div>
          <div className={classes.passwordField}>
            <span className={classes.passwordTitle}>
              {t('auth.signup.confirmPasswordTitle')}
            </span>
            <Input
              onFocus={() =>
                setErrorMessage((prev) => ({ ...prev, confirmPassword: null }))
              }
              required
              disabled={isLoading}
              autoComplete='confirm-new-password'
              type={isShowConfirmPassword ? 'text' : 'password'}
              className={classes.input}
              disableUnderline
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              onBlur={() => {
                setConfirmPassword(confirmPassword.trim());
              }}
              placeholder={t('auth.signup.confirmPasswordPlaceholder')}
              endAdornment={
                <InputAdornment position='end' className={classes.eyesIcon}>
                  <IconButton
                    onClick={handleClickShowConfirmPassWord}
                    size='small'
                  >
                    {isShowConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <Visibility testId='visibilityOff' />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errorMessage.confirmPassword && (
              <div className={classes.errorMessageContainer}>
                <WarningIcon />
                <span className={classes.errorMessage}>
                  {errorMessage.confirmPassword}
                </span>
              </div>
            )}
          </div>
          <div className={classes.privacyField}>
            <Checkbox
              isChecked={isCheckAgreePrivacy}
              onChange={() => setIsCheckAgreePrivacy(!isCheckAgreePrivacy)}
              name='checkbox-agree-privacy'
              size='medium'
            />
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('auth.signup.agreePrivacyPolicy', {
                  link_eula:
                    'https://rs-support-doc.atlassian.net/wiki/spaces/RSCLJAPP/pages/659292184/EULA',
                  link_privacy:
                    'https://rs-support-doc.atlassian.net/wiki/spaces/RSCLJAPP/pages/659062804/PRIVACY+POLICY+BASIC+POLICY+ON+PROTECTION+OF+PERSONAL+INFORMATION',
                }),
              }}
            />
          </div>
        </>

        <button
          type='submit'
          className={`${classes.signUpButton} ${
            isLoading || !isCheckAgreePrivacy ? classes.buttonDisabled : ''
          }`}
          disabled={isLoading || !isCheckAgreePrivacy}
        >
          {isLoading ? <Spinner /> : t('auth.signup.signup')}
        </button>
        <div className={classes.callToActionContainer}>
          <div>{t('auth.signup.callToLogin')}</div>
          <div className={classes.linkAction} onClick={handleLoginNow}>
            {t('auth.login.login')}
          </div>
        </div>
      </form>
    </>
  );
};

export default Signup;
